// IMPORTS
import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection, AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QueryFn} from '@angular/fire/firestore/interfaces';
import {AngularFireStorage} from "@angular/fire/storage";
import {AlertService} from "./alert.service";

type  DocPredicate<T> = string | AngularFirestoreDocument<T>;
type  CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  constructor(private db: AngularFirestore,
              private storage: AngularFireStorage) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  colGroup<T>(ref: string, queryFn?: any): AngularFirestoreCollectionGroup<T> {
    return this.db.collectionGroup<T>(ref, queryFn);
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) return data;
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => a.payload.doc.data()) as T[])
    );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) return data;
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colStateWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).stateChanges(["modified", "added"]).pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) return data;
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colGroupWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return this.colGroup(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          return data;
        }) as T[])
      );
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) return data;
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  getReference(url: string): DocumentReference {
    return this.db.doc(url).ref;
  }

  createID() {
    return this.db.createId();
  }

  async uploadFile(file, key: string, nameFile: string, extension: string) {
    const uploadRef = this.getStorageRefFile(key, nameFile, extension);
    await uploadRef.put(file);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadFileStorage(file, key, nameFile, extension);

    return url;
  }

  private getStorageRefFile(id: string, nameFile: string, extension) {
    return this.storage.ref(`purchase-order/${id}/${nameFile}.${extension}`);
  }

  private uploadFileStorage(data, id, nameFile: string, extension) {
    return this.storage.upload(`medics/${id}/${nameFile}.${extension}`, data);
  }

  update(route: string, data: any) {
    for (let property in data) {
      if (data[property] === undefined) {
        console.error(`La propiedad "${property}" llegó undefined al intentar actualizar el documento "${route}"`);
        AlertService.error(`La propiedad "${property}" llegó undefined al intentar actualizar el documento "${route}"`);
      }
    }
    this.db.doc(route).update(data);
  }
}
