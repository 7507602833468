<div class="h-100 overflow-hidden" style="background: url(assets/images/login-register.jpg);  background-size: cover;">
  <section class="row h-75">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
        <div class="card border-grey border-lighten-3 px-2 py-2 m-0">
          <div class="card-header border-0 text-center">
            <img src="../../../../../assets/images/logo.png" alt="unlock-user"
                 class="img-fluid center-block">
          </div>
          <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2"><span>Iniciar sesión</span>
          </p>
          <div class="card-content">

            <div class="card-body">
              <form [formGroup]="loginForm">
                <fieldset class="form-group position-relative has-icon-left">
                  <input type="email" class="form-control form-control-lg input-lg" id="email"
                         placeholder="Escriba el correo eléctronico" required formControlName="email">
                  <div class="form-control-position">
                    <i class="la la-user"></i>
                  </div>
                </fieldset>

                <fieldset class="form-group position-relative has-icon-left">
                  <input type="password" class="form-control form-control-lg input-lg" id="password"
                         placeholder="Escriba la contraseña" required formControlName="password">
                  <div class="form-control-position">
                    <i class="la la-key"></i>
                  </div>
                </fieldset>

                <a class="btn btn-outline-primary btn-lg btn-block mb-1" (click)="login()">Entrar</a>
              </form>

              <form class="form-horizontal">
                <a (click)="onGoogleLogin()"
                   class="btn btn-outline-primary btn-lg btn-block"><i
                  class="la la-lock"></i>
                  Google</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
