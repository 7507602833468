import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {

  constructor() {
  }

  static getJSONArray(json): any[] {
    let array = [];

    for (let i in json) {
      if (i != 'default') array.push(json[i]);
    }

    return array;
  }

  static disablePointer(itemToDisablePointer: any): any {
    return JSON.parse(JSON.stringify(itemToDisablePointer));
  }

  static convertToURL(text: string): string {
    return text.replace(/ /g, '%20');
  }

  static urlToText(url: string): string {
    return url.replace(/%20/g, ' ');
  }

  textWithoutAccent(text: string) {
    return text.normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1");
  }

  static indexArray(array, propertyIndex?: string) {
    if (!!propertyIndex) {
      return array.reduce((acc, el) => ({
        ...acc,
        [el[propertyIndex]]: el
      }), {});
    } else {
      return array.reduce((acc, el) => ({
        ...acc,
        [el]: el
      }), {});
    }
  }

  static dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a,b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

}
