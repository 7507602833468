import {Component, Input, OnInit} from '@angular/core';
import {DataTableConfig} from '../../shared/interfaces/data-table-config';
import {Observable} from 'rxjs';

@Component({
  selector: 'LastDataComponent',
  template: `
    <app-datatable [rows$]="data"
                   [config]="config">

      <app-datatable-column title="Fecha">
        <ng-template let-row #column>
          {{ row.date }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Volumen Estanque">
        <ng-template let-row #column>
          {{ row.estanqueVolumen }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Nivel Estanque">
        <ng-template let-row #column>
          {{ row.estanqueLevel }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Caudal Flujómetro 1">
        <ng-template let-row #column>
          {{ row.caudalFlujometro1 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Total Flujómetro 1">
        <ng-template let-row #column>
          {{ row.totalFlujometro1 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Caudal Flujómetro 2">
        <ng-template let-row #column>
          {{ row.caudalFlujometro2 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Total Flujómetro 2">
        <ng-template let-row #column>
          {{ row.totalFlujometro2 }}
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  `,
  styles: []
})
export class LastDataComponent implements OnInit {
  @Input() data: Observable<any>;
  config: DataTableConfig = {hasSearch: false, notFoundText: "No hay datos", title: "", height: 150};
  constructor() {
  }

  ngOnInit(): void {
  }

}
