import {Pipe, PipeTransform} from '@angular/core';
import {isUndefined} from "util";

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], search: any, properties: string[] = []): any {
    let newArray = array.filter((item: any) => {
      if (properties.length == 0) {
        if (search == undefined) {
          return true
        }

        const last = Object.keys(item)[Object.keys(item).length - 1];
        for (const itemProperty in item) {
          if (item[itemProperty] != null) {
            let index = item[itemProperty].toString().toLowerCase().indexOf(search.toString().toLowerCase());
            if (index >= 0) {
              return true;
            }
            if (last === itemProperty && index === -1) {
              return false;
            }
          }
        }
      }

      let isValid = false;

      for (const property of properties) {
        if (property === null || property === 'null' || search == undefined) {
          return true
        }

        const last = Object.keys(item)[Object.keys(item).length - 1];

        if (property == undefined) {
          for (const itemProperty in item) {
            if (item[itemProperty] != null) {
              let index = item[itemProperty].toString().toLowerCase().indexOf(search.toString().toLowerCase());
              if (index >= 0) {
                isValid = true;
              }
              if (last === itemProperty && index === -1) {
                // isValid = false;
              }
            }
          }
        } else {
          let field = item;

          for (let iteration of property.split('.')) {
            field = field[iteration]
          }

          if (field != null) {
            let index = field.toString().toLowerCase().indexOf(search.toString().toLowerCase());
            if (index >= 0) {
              isValid = true;
            }
            if (last === field && index === -1) {
              // isValid = false;
            }
          }
        }
      }
      return isValid;
    });
    return newArray;
  }
}
