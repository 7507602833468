import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DataTableConfig} from '../../shared/interfaces/data-table-config';

@Component({
  selector: 'SummaryComponent',
  template: `
    <app-datatable [rows$]="data"
                   [config]="config">

      <app-datatable-column title="Fecha">
        <ng-template let-row #column>
          {{ row.date }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Consumo">
        <ng-template let-row #column>
          {{ row.consumo }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Volumen Total Flujómetro 1">
        <ng-template let-row #column>
          {{ row.volumenFlujometro1 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Volumen Total Flujómetro 2">
        <ng-template let-row #column>
          {{ row.volumenFlujometro2 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Volumen Total Flujómetro 1+2">
        <ng-template let-row #column>
          {{ row.volumenFlujometro12 }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Fluctuación">
        <ng-template let-row #column>
          {{ row.fluctuacion }}
        </ng-template>
      </app-datatable-column>

      <app-datatable-column title="Porcentaje de Error Fluctuación">
        <ng-template let-row #column>
          {{ row.porcentajeErrorFluctuacion }}
        </ng-template>
      </app-datatable-column>
    </app-datatable>
  `,
  styles: [
  ]
})
export class SummaryComponent implements OnInit {
  @Input() data: Observable<any>;
  config: DataTableConfig = {hasSearch: false, notFoundText: "No hay datos", title: ""};

  constructor() { }

  ngOnInit(): void {
  }

}
