// imports
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {NgbModule, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotFoundComponent} from './template-components/not-found/not-found.component';
import {BlockUIModule} from 'ng-block-ui';
import {HttpClientModule} from '@angular/common/http';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DocPipe} from './pipes/doc.pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {FastSearchComponent} from './template-components/fast-search/fast-search.component';
import {ConfirmationDialogService} from './template-services/confirmation-dialog.service';
import {NavbarService} from './template-services/navbar.service';
import {AddFloatButtonComponent} from './components/add-float-button/add-float-button.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {FilterPipe} from './pipes/filter.pipe';
import {DataTableColumnComponent} from './components/data-table-column/data-table-column.component';
import {SafePipe} from './pipes/safe.pipe';
import { SearchItemsModalComponent } from './template-components/search-items/search-items-modal.component';
import {UploadFileStorageComponent} from "./template-components/upload-file-storage/upload-file-storage.component";
import { JsonPreviewComponent } from './modals/json-preview/json-preview.component';
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {ScrollingModule} from '@angular/cdk/scrolling';
import { DotsCurrencyPipe } from './pipes/dots-currency.pipe';

@NgModule({
  declarations: [
    NotFoundComponent,
    DocPipe,
    FastSearchComponent,
    AddFloatButtonComponent,
    DataTableComponent,
    FilterPipe,
    DataTableColumnComponent,
    SafePipe,
    UploadFileStorageComponent,
    SearchItemsModalComponent,
    JsonPreviewComponent,
    DotsCurrencyPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    BlockUIModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    NgxJsonViewerModule,
    ScrollingModule
  ],
  exports: [
    // COMPONENTS
    NotFoundComponent,
    FastSearchComponent,
    SearchItemsModalComponent,

    // PIPES
    DocPipe,
    FilterPipe,

    // MODULES
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    AddFloatButtonComponent,
    DataTableComponent,
    DataTableColumnComponent,
    SafePipe,
    UploadFileStorageComponent
  ],
  providers: [
    ConfirmationDialogService,
    NavbarService,
    DatePipe
  ]
})

export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
