import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserService} from '../services/user.service';
import {AuthService} from '../template-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private _auth: AuthService,
              private _user: UserService) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this._auth.loadFirebaseUser();
    if (!this._auth.user) {
      if (state.url == '/auth/login') return true;
      return this.router.navigateByUrl('auth');
    }
    if (state.url == '/admin/home') return true;
    return this.router.navigateByUrl('admin');
  }
}
