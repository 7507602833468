import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbCarouselConfig, NgbModalConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';

// Routing
import {appRoutes} from './app.routing';
// Components
import {AppComponent} from './app.component';
import {SettingsModule} from './layout/settings/settings.module';
import {ThemeSettingsConfig} from './layout/settings/theme-settings.config';
import {MenuSettingsConfig} from './layout/settings/menu-settings.config';
import {HeaderComponent} from './layout/header/header.component';
import {VerticalComponent as HeaderVerticalComponent} from './layout/header/vertical/vertical.component';
import {HorizontalComponent as HeaderHorizontalComponent} from './layout/header/horizontal/horizontal.component';
import {FullLayoutNavbarComponent} from './layout/header/full-layout-navbar/full-layout-navbar.component';
import {FooterComponent} from './layout/footer/footer.component';
import {NavigationComponent as AppNavigationComponent} from './layout/navigation/navigation.component';
import {PublicLayoutComponent} from './layout/public-layout/public-layout.component';
import {PrivateLayoutComponent} from './layout/private-layout/private-layout.component';
import {VerticalnavComponent} from './layout/navigation/verticalnav/verticalnav.component';
import {HorizontalnavComponent} from './layout/navigation/horizontalnav/horizontalnav.component';
// perfect scroll bar
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// spinner
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {RouterModule} from '@angular/router';
import {CustomizerComponent} from './layout/customizer/customizer.component';
import {ChartsModule} from 'ng2-charts';
import {BreadcrumbModule} from './layout/breadcrumb/breadcrumb.module';
import {HorizontalCustomizerComponent} from './layout/customizer/horizontal-customizer/horizontal-customizer.component';
import {BlockTemplateComponent} from './layout/blockui/block-template.component';
import {BlockUIModule} from 'ng-block-ui';
import {FullLayoutComponent} from './layout/full-layout/full-layout.component';
import {ToastrModule} from 'ngx-toastr';
import {SharedModule} from './shared/shared.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {Angular2CsvModule} from 'angular2-csv';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireDatabaseModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    // Settings modules
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    LoadingBarRouterModule,
    BlockUIModule.forRoot(),
    SharedModule,
    ModalModule.forRoot(),
    Angular2CsvModule
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    CustomizerComponent,
    HorizontalCustomizerComponent,
    BlockTemplateComponent,
    FullLayoutComponent
  ],
  providers: [
    NgbCarouselConfig,
    NgbModalConfig,
    AngularFireStorage
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule {
}
