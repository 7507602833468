// IMPORTS
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';
import * as firebase from 'firebase';
import {auth, User} from 'firebase/app';
import {BehaviorSubject, Observable} from 'rxjs';
import {FirebaseDataService} from './firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user: User = null;
  $user: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);

  constructor(private afAuth: AngularFireAuth,
              private db: FirebaseDataService,
              private afs: AngularFirestore,
              private router: Router) {
  }

  static getMessageError(code: string): string {
    switch (code) {
      case 'auth/email-already-exists':
        return 'Correo electrónico en uso.';

      case 'auth/invalid-email' :
        return 'Correo electrónico inválido.';

      case 'auth/operation-not-allowed':
        return 'Esta operación no está permitida.';

      case 'auth/weak-password':
        return 'Contraseña muy débil.';

      case 'auth/wrong-password':
        return 'Contraseña incorrecta';

      case 'auth/invalid-password':
        return 'Contraseña inválida, mínimo 6 caracteres';

      case 'auth/invalid-uid':
        return 'Usuario inválido';

      case 'auth/user-disabled':
        return 'Tu usuario ha sido bloqueado';

      case 'auth/user-not-found':
        return 'El usuario no fue encontrado, revise su correo electrónico';

      case 'auth/network-request-failed':
        return 'Error en la conexión';

      case 'auth/email-already-in-use':
        return 'El email ya está en uso';

      default:
        console.log(code);
        return `Pruebe de nuevo. Si el problema persiste contacte con el equipo de soporte: soporte@tecnologiasintech.com`;
    }
  }

  async loginGoogle() {
    return this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  async reAuth(email: string, pass: string): Promise<UserCredential> {
    await this.afAuth.user.pipe(take(1)).toPromise();
    return this.afAuth.signInWithEmailAndPassword(email, pass);
  }

  async getCurrentUser(): Promise<firebase.User> {
    if (!this.user) return await this.loadFirebaseUser();
    return this.user;
  }

  createAccount(email: string, pass: string): Promise<UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, pass);
  }

  async loadFirebaseUser(): Promise<User> {
    return new Promise(resolve => {
      this.afAuth.user.subscribe(data => {
        this.user = data;
        this.$user.next(data);
        resolve(data);
      })
    })
  }

  async signOut(): Promise<void> {
    await this.afAuth.signOut();
    localStorage.clear();
    await this.router.navigateByUrl('auth');
  }

  getWhiteList(): Observable<any[]> {
    return this.db.colWithIds$('whitelist', ref => ref
      .where('trash', '==', false));
  }

  addToWhitelist(email: string): void {
    this.afs.collection('whitelist').add({
      email,
      isRegistered: false,
      trash: false
    })
  }

  updateWhitelistItem(itemKey: string, item): void {
    this.afs.doc(`whitelist/${itemKey}`).update(item);
  }
}

