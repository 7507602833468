import { Component, OnInit } from '@angular/core';
import labelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.css']
})
export class ChangeLogComponent implements OnInit {

  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  };

  constructor() { }

  ngOnInit() {
    this.breadcrumb = {
      'mainlabel': 'ChangeLog',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/sales'
        },
        {
          'name': 'ChangeLog',
          'isLink': false,
          'link': '#'
        },
      ]
    };
  }
}


