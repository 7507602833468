import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'LastHourChartComponent',
  template: `
    <div id="lastHour" style="height: 400px"></div>
  `,
  styles: []
})
export class LastHourChartComponent implements OnInit, AfterViewInit {
  @Input() estanqueData = [];
  @Input() flujometro1Data = [];
  @Input() flujometro2Data= [];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.createChart();
  }

  createChart() {
    am4core.useTheme(am4themes_animated);
// Themes end


// Create chart instance
    let chart: any = am4core.create('lastHour', am4charts.XYChart);
    chart.dateFormatter.inputDateFormat = "DD-MM-yyyy HH:mm";

    chart.colors.list = [am4core.color('rgb(0,0,0)'), am4core.color('rgb(253, 98, 94)'), am4core.color('rgb(242, 200, 15)')]
// Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.minZoomCount = 5;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    createSeries(1, 'Estanque', this.estanqueData);
    createSeries(2, 'Flujómetro 1', this.flujometro1Data);
    createSeries(3, 'Flujómetro 2', this.flujometro2Data);

// Create series
    function createSeries(s, name, data) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = 'value' + s;
      series.tooltipText = "{valueY}";

      series.dataFields.dateX = 'date';
      series.name = name;

      let segment = series.segments.template;
      segment.interactionsEnabled = true;

      let hoverState = segment.states.create('hover');
      hoverState.properties.strokeWidth = 3;

      let dimmed = segment.states.create('dimmed');
      dimmed.properties.stroke = am4core.color('#dadada');

      segment.events.on('over', function (event) {
        processOver(event.target.parent.parent.parent);
      });

      segment.events.on('out', function (event) {
        processOut(event.target.parent.parent.parent);
      });

      series.data = data;
      return series;
    }

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    chart.scrollbarX = scrollbarX;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'right';
    chart.legend.scrollable = true;
    chart.legend.itemContainers.template.events.on('over', function (event) {
      processOver(event.target.dataItem.dataContext);
    });

    chart.legend.itemContainers.template.events.on('out', function (event) {
      processOut(event.target.dataItem.dataContext);
    });

    function processOver(hoveredSeries) {
      hoveredSeries.toFront();

      hoveredSeries.segments.each(function (segment) {
        segment.setState('hover');
      });

      chart.series.each(function (series) {
        if (series != hoveredSeries) {
          series.segments.each(function (segment) {
            segment.setState('dimmed');
          });
          series.bulletsContainer.setState('dimmed');
        }
      });
    }

    function processOut(hoveredSeries) {
      chart.series.each(function (series) {
        series.segments.each(function (segment) {
          segment.setState('default');
        });
        series.bulletsContainer.setState('default');
      });
    }
  }
}
