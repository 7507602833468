<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
     [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
         (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)">
      <ul class="nav navbar-nav flex-row">
        <!-- Remove position relative in responsive -->
        <li class="nav-item mobile-menu d-lg-none mr-auto">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)">
            <i class="feather ft-menu font-large-1"></i>
          </a>
        </li>
        <li class="nav-item mr-auto">
          <a [routerLink]="['/admin/home']" class="navbar-brand" *ngIf="isMobile">
            <img class="brand-logo" style="filter: brightness(0) invert(1);" alt="Colsan logo"
                 [src]="_themeSettingsConfig.brand.logo.value">
            <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
          </a>
          <img [src]="_themeSettingsConfig.brand.logo.value"
               id="brand"
               [ngClass]="{
               'brand-large': _themeSettingsConfig.menu == 'expand',
               'brand-sm' : _themeSettingsConfig.menu == 'collapse'}"
               alt="" *ngIf="!isMobile">
        </li>
        <li class="nav-item d-none d-md-block nav-toggle">
          <a [routerLink]="" class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
             (click)="toggleFixMenu($event)">
            <i class="feather toggle-icon font-medium-3 white"
               [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse','ft-toggle-right': _themeSettingsConfig.menu == 'expand'}">
            </i>
          </a>
        </li>
        <li class="nav-item d-lg-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
                                          data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i
          class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <!-- New-->
    <div class="navbar-container content" [hidden]="isMobile && !showNavbar">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
        </ul>
        <ul class="nav navbar-nav float-right">
          <li class="nav-item mr-auto">
            <a [routerLink]="['/admin/home']" class="navbar-brand" *ngIf="isMobile">
              <img class="brand-logo" style="filter: brightness(0) invert(1);" alt="Colsan logo"
                   [src]="'assets/images/logo-kinross.jpg'">
              <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
            </a>
            <img [src]="'assets/images/logo-kinross.jpg'"
                 [ngClass]="{
               'brand-large': _themeSettingsConfig.menu == 'expand',
               'brand-sm' : _themeSettingsConfig.menu == 'collapse'}"
                 style="margin-top: 1.1rem !important;"
                 alt="" *ngIf="!isMobile">
          </li>
        </ul>
      </div>
    </div>
    <!-- New-->
  </div>
</nav>
