<div class="row">
    <div class="col-md-12">
        <m-card [options]="options">
            <ng-container mCardHeaderTitle>
                Actualizaciones:
            </ng-container>
            <ng-container mCardBody>
                <h5 class="my-2">22-04-2021 [v 1.3.8]</h5>
                <div class="card-text">
                    <ul>
                        <li>Filtro TODOS en las listas</li>
                        <li>Editar Costos y Categorías dentro de facturas</li>
                        <li>Correción que no cargaban reportes</li>
                        <li>Fecha de pago es Fecha de generación más crédito seleccionado</li>
                        <li>Nuevo crédito de 60 días</li>
                        <li>Se quitaron proveedores que no tienen gastos en reportes</li>
                        <li>Se corrigió que facturas abre muy lento</li>
                        <li>Nuevo botón de mover fecha de factura en reportes</li>
                        <li>Se cambió el texto de ciertos tooltips en proyectos</li>
                        <li>Cuando un proyecto acaba se modifica el status de todos los usuarios que estaban asignados a "Calificado Colsan"</li>
                        <li>Ahora los comprobantes de pago se agrega su nombre con rut y monto</li>
                    </ul>
                </div>
                <h5 class="my-2">29-03-2021 [v 1.3.5]</h5>
                <div class="card-text">
                    <ul>
                        <li>Se corrigió el funcionamiento de código en compras.</li>
                        <li>Se arregló error en reportes que no mostraba facturas sin proyecto.</li>
                        <li>Se agregó página de actualizaciones.</li>
                        <li>Cuando se seleccionaba un comprobante de pagos aparecía 'DOCTYPE'</li>
                        <li>Ahora se pueden seleccionar facturas desde el comprobante de pagos</li>
                        <li>Cuando importamos comprobantes de pago se importan con el nombre "RUT - $MONTO"</li>
                        <li>Agregar categoría cuando se selecciona un centro de costo en facturas </li>
                        <li>Cuando se asigna un comprobante de pagos se cambia su nombre al siguiente formato "Pago - Proveedor - Número de factura"</li>
                    </ul>
                </div>
                <h5 class="my-2">18-03-2021 [v 1.3.4]</h5>
                <div class="card-text">
                    <ul>
                        <li>Se agregó tabla de reportes del año.</li>
                        <li>Se arreglaron bugs.</li>
                    </ul>
                </div>
            </ng-container>
        </m-card>
    </div>
</div>
