import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {SharedModule} from '../shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {AdminRoutes} from './admin-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {ChartistModule} from 'ng-chartist';
import {ChartsModule} from 'ng2-charts';
import {CardModule} from '../shared/template-components/partials/general/card/card.module';
import {HttpClientModule} from '@angular/common/http';
import {UiSwitchModule} from 'ngx-ui-switch';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ChangeLogComponent} from './change-log/change-log.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { GaugeChartComponent } from './components/gauge-chart.component';
import { CombustibleRgChartComponent } from './components/combustible-rg-chart.component';
import { LastHourChartComponent } from './components/last-hour-chart.component';
import { LastDataComponent } from './components/last-data.component';
import { SummaryComponent } from './components/summary.component';
import {Angular2CsvModule} from 'angular2-csv';

@NgModule({
  declarations: [
    HomeComponent,
    ChangeLogComponent,
    GaugeChartComponent,
    CombustibleRgChartComponent,
    LastHourChartComponent,
    LastDataComponent,
    SummaryComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminRoutes),
    SharedModule,
    NgxDatatableModule,
    NgSelectModule,
    MatTabsModule,
    NgxQRCodeModule,
    ChartistModule,
    CardModule,
    ChartsModule,
    HttpClientModule,
    UiSwitchModule,
    NgxChartsModule,
    NgxSpinnerModule,
    TooltipModule,
    ScrollingModule,
    Angular2CsvModule
  ]
})
export class AdminModule {
}
