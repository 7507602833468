import {Routes} from '@angular/router';
import {AuthModule} from './auth/auth.module';
import {AdminModule} from './admin/admin.module';
import {AuthGuard} from './shared/guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => AdminModule,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'admin'
  }
];
