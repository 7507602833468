import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare const $

@Component({
  selector: 'app-add-float-button',
  template: `
    <button  type="button"  class="btn btn-{{color}} btn-float-right add-btn"
            data-toggle="tooltips" data-placement="top" title="{{tooltip}}"
            (click)="clickEmmiter.emit()">
      <i *ngIf="!!icon" class="{{icon}}"></i>
    </button>
  `,
  styleUrls: ['./add-float-button.component.scss']
})
export class AddFloatButtonComponent implements OnInit {
  @Output() clickEmmiter: EventEmitter<void> = new EventEmitter();
  @Input() icon: string = 'fa fa-plus';
  @Input() color: string = 'primary';
  @Input() tooltip: string = 'Agregar';

  constructor() {
  }

  ngOnInit(): void {

  }

}
