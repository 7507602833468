import {Injectable} from '@angular/core';
import {User, User as UserInterface} from '../interfaces/user';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/storage';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User = null;
  $user: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);
  id: string;

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private http: HttpClient) {
  }

  async addUserByKey(userKey: string, user: any): Promise<void> {
    await this.db.doc(`users/${userKey}`).set(user);
  }

  async getSpecificUser(userKey: string): Promise<User> {
    return await this.db.docWithId$<UserInterface>(`users/${userKey}`)
      .pipe(take(1))
      .toPromise()
  }

  getSpecificUser2(userKey: string) {
    return this.afs.doc<UserInterface>(`users/${userKey}`).valueChanges()
  }

  async get(userKey: string): Promise<User> {
    if (!this.user) return await this.loadUser(userKey)
    return this.user;
  }

  async loadUser(userKey: string): Promise<User> {
    return new Promise<User>(resolve => {
      this.afs.doc<UserInterface>(`users/${userKey}`)
        .valueChanges()
        .subscribe(data => {

          if (!data) resolve(null)

          this.user = data;
          this.user.key = userKey;
          this.$user.next(data);
          resolve(data);
        })
    })
  }

  getReference(userKey: string): DocumentReference {
    return this.afs.doc(`users/${userKey}`).ref;
  }

  getAll(): Observable<User[]> {
    return this.db.colWithIds$<User>('users')
  }

  getAllApplicants(): Observable<User[]> {
    return this.db.colWithIds$<User>('users')
  }

  getAllOtherThanProject(project: DocumentReference): Observable<User[]> {
    return this.db.colWithIds$<User>('users')
  }

  getAllByProject(projectReference): Observable<User[]> {
    return this.db.colWithIds$<User>('users', ref => ref
      .where('projects', 'array-contains', projectReference))
  }

  getUsers(): Observable<User[]> {
    return this.db.colWithIds$<User>('users', ref => ref
      .where('isDisable', '==', false)
      .orderBy('name', 'desc'));
  }

  update(userKey: string, user: User) {
    this.afs.doc(`users/${userKey}`).update(user);
  }

  async uploadDocument(folder, secondFolder, document, key: string, documentName: string) {
    if (key == null) {
      this.id = this.afs.createId();
    } else {
      this.id = key;
    }
    const uploadRef = this.getStorageRefDocument(folder, secondFolder, this.id, documentName);
    await uploadRef.put(document);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadDocumentStorage(folder, secondFolder, document, this.id, documentName);

    return url;
  }

  uploadDocumentStorage(folder, secondFolder, data, id, documentName) {
    return this.storage.upload(`${folder}/${secondFolder}/${id}/${documentName}`, data);
  }

  getStorageRefDocument(folder, secondFolder, id, documentName) {
    return this.storage.ref(`${folder}/${secondFolder}/${id}/${documentName}`);
  }

  resetPassword(uid: string, newPassword: string): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/resetPassword`, {uid, newPassword}).pipe(take(1)).toPromise()
  }

  createAccount(email: string, password: string) {
    return this.http.post(`${environment.apiBaseURL}/createAccount`, {
      email,
      password
    }).pipe(take(1)).toPromise()
  }

  async uploadPicture(photo, userKey) {
    const uploadRef = this.getStorageRef(userKey);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, userKey);

    return url;
  }

  getStorageRef(id: string) {
    return this.storage.ref(`users/${id}/profile.jpeg`);
  }

  uploadImage(data, id) {
    return this.storage.upload(`users/${id}/profile.jpeg`, data);
  }

  getComments(userKey: any): Observable<any[]> {
    return this.db.colWithIds$(`users/${userKey}/comments`, ref => ref
      .orderBy('date', 'desc'));
  }
}
