<ng-container *ngIf="rows$ | async as rows; else loaded">
  <ng-container *ngIf="rows.length > 0; else notFound">
    <div class="card">
      <div class="card-header" *ngIf="!!config.title || config.hasSearch">
        <h4 class="card-title">{{config.title}}</h4>
        <h4 class="card-title mt-2">Se encontraron {{rows.length}} resultados</h4>
        <h4 class="card-title mt-2" *ngIf="!!config.propTotal">Total: {{calculateTotal(rows) | currency  : 'USD' : 'symbol' : '1.0-0' | dotsCurrency}}</h4>
        <div class="heading-elements" *ngIf="config.hasSearch">
          <ul class="list-inline mb-0">
            <li class="mr-1">
              <button class="btn btn-warning" (click)="printList()" app-tooltip="Imprimir lista">
                <i class="la la-print"></i>
              </button>
            </li>
            <li>
              <div class="form-group position-relative has-icon-left">
                <input type="text" class="form-control" id="iconLeft4" placeholder="Buscar" [(ngModel)]="search">
                <div class="form-control-position">
                  <i class="feather ft-search"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-content mt-3" #componentID>
        <div class="table-responsive" fxFlex="auto" [perfectScrollbar]="config">
          <cdk-virtual-scroll-viewport itemSize="30" class="example-viewport" [style.height]="(!!config.height ? config.height : 500) + 'px'">
            <table class="table border_bottom table-hover">
              <thead>
              <tr>
                <th scope="col" class="border-top-0"
                    [ngClass]="{'d-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                    *ngFor="let column of columns"><span
                  *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)">{{column.title}}</span>
                </th>
                <th *ngIf="isLocal"></th>
              </tr>
              </thead>
              <tbody>
              <tr class="cursor-pointer "
                  *cdkVirtualFor="let row of rows | filter: search : !!config.filtersFields ? config.filtersFields : []; index as index; trackBy: trackByKey">
                <td class="text-truncate text-column"
                    [ngClass]="{'text-small' : printSmall && isPrinting, 'd-none' : !((column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting))}"
                    *ngFor="let column of columns"
                    (click)="selectRow(column.isClickable, row)">
                  <ng-container
                    [ngTemplateOutlet]="column.template"
                    [ngTemplateOutletContext]="{ $implicit: row, index: index }"
                    *ngIf="(column.isVisiblePrint || !isPrinting ) && (!column.onlyPrint || isPrinting)">
                  </ng-container>
                </td>
                <td *ngIf="isLocal">
                  <button class="btn btn-info" (click)="openJsonPreview(row)"><i class="fa fa-file-code-o"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #notFound>
    <app-not-found size="medium" [title]="config.notFoundText"></app-not-found>
  </ng-template>

</ng-container>

<ng-template #loaded>
  <div class="loader-wrapper">
    <div class="loader-container">
      <div class="ball-clip-rotate loader-primary">
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
