import {Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {PrivateLayoutComponent} from '../layout/private-layout/private-layout.component';
import {ChangeLogComponent} from './change-log/change-log.component';

export const AdminRoutes: Routes = [
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'changeLog',
        component: ChangeLogComponent
      },
      {
        path: '**',
        redirectTo: 'home'
      }
    ]
  },
];
