import {AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import {GaugeChartComponent} from '../../components/gauge-chart.component';
import {of, Subscription} from 'rxjs';
import {AngularFireDatabase} from '@angular/fire/database';
import moment from 'moment-timezone';
import * as _ from 'lodash';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

export interface Combustible {
  FIT01_Value: number;
  FIT01_Value_B: number;
  FIT02_Value: number;
  FIT02_Value_B: number;
  Lit01_Value: number;
  Lit01_Value_B: number;
  Tiempo: string;
  time: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('estanque') estanqueChart: GaugeChartComponent;
  @ViewChild('flujimetro') flujimetroChart: GaugeChartComponent;
  @ViewChild('flujimetro2') flujimetro2Chart: GaugeChartComponent;
  filterSubscription: Subscription = new Subscription();
  startDate = moment(new Date()).tz('America/Santiago').subtract(1, 'week').format('yyyy-MM-DD');
  endDate = moment(new Date()).tz('America/Santiago').format('yyyy-MM-DD');
  combustibleRgDataByRangeFiler = null;
  combustibleRgData = null;
  combustibleRgDataTmp = null;
  isFilter: boolean;
  data = {
    score: 10,
    gradingData: [
      {
        title: 'Muy bajo',
        color: '#ee1f25',
        lowScore: 0,
        highScore: 10
      },
      {
        title: 'Bajo',
        color: '#f04922',
        lowScore: 10,
        highScore: 20
      },
      {
        title: 'Medio',
        color: '#fdae19',
        lowScore: 20,
        highScore: 30
      },
      {
        title: 'Alto',
        color: '#fdae19',
        lowScore: 30,
        highScore: 40
      },
      {
        title: 'Muy Alto',
        color: '#0f9747',
        lowScore: 40,
        highScore: 50
      }
    ]
  };
  flujometrosData = {
    score: 10,
    gradingData: [
      {
        title: 'Muy bajo',
        color: '#0f9747',
        lowScore: 0,
        highScore: 50
      },
      {
        title: 'Bajo',
        color: '#0f9747',
        lowScore: 50,
        highScore: 100
      },
      {
        title: 'Medio',
        color: '#fdae19',
        lowScore: 100,
        highScore: 150
      },
      {
        title: 'Muy Alto',
        color: '#ee1f25',
        lowScore: 150,
        highScore: 200
      }
    ]
  };
  lastData = null;
  summaryDate = null;
  lastHourEstanqueData = null;
  lastHourFlujometro1Data = null;
  lastHourFlujometro2Data = null;
  hand: any;
  allData = [];
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [],
    showTitle: true,
    title: 'Reporte Plataforma Combustibles',
    useBom: false,
    removeNewLines: true,
    keys: ['Fecha', 'Volumen Estanque', 'Nivel Estanque', 'Caudal Flujómetro 1', 'Total Flujómetro 1', 'Caudal Flujómetro 2', 'Total Flujómetro 2']
  };
  @BlockUI() blockUI: NgBlockUI;
  lastHour: any;
  lastWeek: any;

  constructor(private db: AngularFireDatabase) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    document.querySelector('#downloadLastHour > button').innerHTML = 'Descargar última hora';
    document.querySelector('#downloadLastHour > button').className = 'btn btn-info';

    document.querySelector('#downloadLastWeek > button').innerHTML = 'Descargar última semana';
    document.querySelector('#downloadLastWeek > button').className = 'btn btn-info ml-4';
  }

  ngAfterViewInit() {
    this.loadData();
  }

  filterData() {
    this.filterSubscription = this.db.list('data', ref => ref
      .orderByChild('Tiempo')
      .startAt(moment(this.startDate + ' 04:00').tz('America/Santiago').format('yyyy-MM-DD HH:mm'))
      .endAt(moment(this.endDate + ' 04:00').tz('America/Santiago').add(1, 'day').format('yyyy-MM-DD HH:mm')))
      .valueChanges()
      .subscribe((data: Combustible[]) => {

        data = data.map(item => {
          let date = moment(item.Tiempo).tz('America/Santiago').format('YYYY-MM-DD HH:mm:ss');
          // let utc = moment.utc(date).toDate();
          // let local = moment(utc).local().subtract(4, 'hour').format('YYYY-MM-DD HH:mm:ss');

          return {
            ...item,
            FIT01_Value: +item.FIT01_Value,
            FIT01_Value_B: +item.FIT01_Value_B,
            FIT02_Value: +item.FIT02_Value,
            FIT02_Value_B: +item.FIT02_Value_B,
            Lit01_Value: +item.Lit01_Value,
            Lit01_Value_B: +item.Lit01_Value_B,
            time: moment(date).tz('America/Santiago').toDate().getTime(),
            Tiempo: date
          };
        });

        this.loadCombustibleRGByRangeFilter(data);
      });
  }

  toggleFilter() {
    this.isFilter = !this.isFilter;
    if (!this.isFilter) {
      this.filterSubscription.unsubscribe();
      setTimeout(() => {
        this.combustibleRgData = [...this.combustibleRgDataTmp];
      }, 1000);
    } else {
      this.combustibleRgDataTmp = [...this.combustibleRgData];
      this.combustibleRgData = null;
    }
  }

  loadData() {
    this.blockUI.start('Cargando datos...');

    this.db.list('data', ref => ref
      .orderByChild('Tiempo')
      .startAt(moment(new Date()).tz('America/Santiago').subtract(1, 'week').format('yyyy-MM-DD HH:mm')))
      .valueChanges()
      .subscribe(async (data: Combustible[]) => {

        data = data.map(item => {
          let date = moment(item.Tiempo).tz('America/Santiago').format('YYYY-MM-DD HH:mm:ss');
          let utc = moment.utc(date).toDate();
          let local = moment(utc).tz('America/Santiago').subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss');

          return {
            ...item,
            FIT01_Value: +item.FIT01_Value,
            FIT01_Value_B: +item.FIT01_Value_B,
            FIT02_Value: +item.FIT02_Value,
            FIT02_Value_B: +item.FIT02_Value_B,
            Lit01_Value: +item.Lit01_Value,
            Lit01_Value_B: +item.Lit01_Value_B,
            time: moment(local).tz('America/Santiago').toDate().getTime(),
            Tiempo: local
          };
        });

        this.allData = data;

        this.prepareLastHourData();

        this.prepareLastWeekData();

        this.loadPrincipalChart(data);

        await this.loadCombustibleRG(data);

        await this.loadLastHourData(data);

        await this.loadLastData(data);

        this.blockUI.stop();
      });
  }

  loadPrincipalChart(data) {
    data = _.orderBy(data, 'Tiempo', 'asc');
    const lastData = data[data.length - 1];

    this.estanqueChart.hand.showValue(lastData?.Lit01_Value, 1000, am4core.ease.cubicOut);
    this.flujimetroChart.hand.showValue(lastData?.FIT01_Value, 1000, am4core.ease.cubicOut);
    this.flujimetro2Chart.hand.showValue(lastData?.FIT02_Value, 1000, am4core.ease.cubicOut);
  }

  loader(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  async loadCombustibleRG(data) {
    this.summaryDate = null;
    this.combustibleRgData = null;

    await this.loader();

    let dataCombustible = [];
    let summaryData = [];

    let dailyData: any = _.groupBy(data
        .map(item => ({
          ...item,
          day: moment(item.Tiempo).tz('America/Santiago').format('DD/MM/yyyy')
        }))
        .filter(item => item.time >= moment().tz('America/Santiago').subtract(1, 'week').toDate().getTime())
      , 'day');

    for (const dailyDataKey in dailyData) {
      dailyData[dailyDataKey] = _.orderBy(dailyData[dailyDataKey], ['time'], ['asc']);

      let lastOfDay = this.getLastOfDay(dailyData, dailyDataKey);
      let firstOfDay = this.getFirstDataOfDay(dailyData, dailyDataKey);

      dataCombustible.push({
        category: `${dailyDataKey.substr(3, 2)}-${dailyDataKey.substr(0, 2)}-${dailyDataKey.substr(6, 4)}`,
        first: this.getConsumoEstanque(lastOfDay, firstOfDay).toFixed(2),
        second: this.getConsumoGeneradores(lastOfDay, firstOfDay).toFixed(2)
      });

      summaryData.push({
        date: dailyDataKey,
        consumo: this.getConsumoEstanque(lastOfDay, firstOfDay).toFixed(2),
        volumenFlujometro1: this.getConsumoGenerador1(lastOfDay, firstOfDay).toFixed(2),
        volumenFlujometro2: this.getConsumoGenerador2(lastOfDay, firstOfDay).toFixed(2),
        volumenFlujometro12: this.getConsumoGeneradores(lastOfDay, firstOfDay).toFixed(2),
        fluctuacion: (this.getConsumoEstanque(lastOfDay, firstOfDay) - this.getConsumoGeneradores(lastOfDay, firstOfDay)).toFixed(2),
        porcentajeErrorFluctuacion: (((this.getConsumoEstanque(lastOfDay, firstOfDay) - this.getConsumoGeneradores(lastOfDay, firstOfDay)) / this.getConsumoEstanque(lastOfDay, firstOfDay)) * 100).toFixed(2) + '%',
      });
    }

    this.combustibleRgData = _.orderBy(dataCombustible, 'category', 'asc');
    this.summaryDate = of(_.orderBy(summaryData, 'date', 'desc'));
  }

  async loadCombustibleRGByRangeFilter(data) {
    this.combustibleRgDataByRangeFiler = null;

    await this.loader();

    let dataCombustible = [];

    let dailyData: any = _.groupBy(data
        .map(item => ({
          ...item,
          day: moment(item.time).tz('America/Santiago').format('DD/MM/yyyy')
        }))
      , 'day');

    for (const dailyDataKey in dailyData) {
      dailyData[dailyDataKey] = _.orderBy(dailyData[dailyDataKey], ['time'], ['asc']);

      let lastOfDay = this.getLastOfDay(dailyData, dailyDataKey);
      let firstOfDay = this.getFirstDataOfDay(dailyData, dailyDataKey);

      dataCombustible.push({
        category: `${dailyDataKey.substr(3, 2)}-${dailyDataKey.substr(0, 2)}-${dailyDataKey.substr(6, 4)}`,
        first: this.getConsumoEstanque(lastOfDay, firstOfDay).toFixed(2),
        second: this.getConsumoGeneradores(lastOfDay, firstOfDay).toFixed(2)
      });
    }

    this.combustibleRgDataByRangeFiler = _.orderBy(dataCombustible, 'category', 'asc');
  }

  getLastOfDay(dailyData, dailyDataKey): number {
    return dailyData[dailyDataKey][dailyData[dailyDataKey].length - 1];
  }

  getFirstDataOfDay(dailyData, dailyDataKey): number {
    return dailyData[dailyDataKey][0];
  }

  getConsumoEstanque(lastOfDay, firstOfDay) {
    return firstOfDay.Lit01_Value - lastOfDay.Lit01_Value;
  }

  getConsumoGeneradores(lastOfDay, firstOfDay) {
    return this.getConsumoGenerador1(lastOfDay, firstOfDay) + this.getConsumoGenerador2(lastOfDay, firstOfDay);
  }

  getConsumoGenerador1(lastOfDay, firstOfDay) {
    return (lastOfDay.FIT01_Value_B - firstOfDay.FIT01_Value_B);
  }

  getConsumoGenerador2(lastOfDay, firstOfDay) {
    return (lastOfDay.FIT02_Value_B - firstOfDay.FIT02_Value_B);
  }

  async loadLastHourData(data) {
    this.lastHourFlujometro2Data = null;

    await this.loader();

    let lastHour = data.filter(item => item.time >= moment(data[data.length - 1].time).tz('America/Santiago').startOf('day').toDate().getTime());
    this.lastHourEstanqueData = lastHour.map(item => ({date: item.Tiempo, value1: item.Lit01_Value}));
    this.lastHourFlujometro1Data = lastHour.map(item => ({date: item.Tiempo, value2: item.FIT01_Value}));
    this.lastHourFlujometro2Data = lastHour.map(item => ({date: item.Tiempo, value3: item.FIT02_Value}));
  }

  async loadLastData(data) {
    data = _.orderBy(data, 'Tiempo', 'asc');

    await this.loader();

    let last = data[data.length - 1];
    this.lastData = of([
      {
        date: last.Tiempo,
        estanqueVolumen: `${last.Lit01_Value.toFixed(2)} m3`,
        estanqueLevel: `${last.Lit01_Value_B.toFixed(2)} m`,
        caudalFlujometro1: `${last.FIT01_Value.toFixed(2)} l/h`,
        totalFlujometro1: `${last.FIT01_Value_B.toFixed(2)} m3`,
        caudalFlujometro2: `${last.FIT02_Value.toFixed(2)} l/h`,
        totalFlujometro2: `${last.FIT02_Value_B.toFixed(2)} m3`,
      }
    ]);
  }

  prepareLastHourData() {
    this.lastHour = this.formatToExcel(this.allData
      .filter(item => item.time >= moment(this.allData[this.allData.length - 1].time).tz('America/Santiago').startOf('hour').toDate().getTime()));
  }

  prepareLastWeekData() {
    this.lastWeek = this.formatToExcel(this.allData
      .filter(item => item.time >= moment(this.allData[this.allData.length - 1].time).tz('America/Santiago').subtract(1, 'week').toDate().getTime()));
  }

  formatToExcel(data) {
    data = data.map(item => ({
      ['Caudal Flujómetro 1']: item.FIT01_Value,
      ['Total Flujómetro 1']: item.FIT01_Value_B,
      ['Caudal Flujómetro 2']: item.FIT02_Value,
      ['Total Flujómetro 2']: item.FIT02_Value_B,
      ['Volumen Estanque']: item.Lit01_Value,
      ['Nivel Estanque']: item.Lit01_Value_B,
      ['Fecha']: item.Tiempo
    }));

    data.splice(0, 0, {
      ['Caudal Flujómetro 1']: 'Caudal Flujómetro 1',
      ['Total Flujómetro 1']: 'Total Flujómetro 1',
      ['Caudal Flujómetro 2']: 'Caudal Flujómetro 2',
      ['Total Flujómetro 2']: 'Total Flujómetro 2',
      ['Volumen Estanque']: 'Volumen Estanque',
      ['Nivel Estanque']: 'Nivel Estanque',
      ['Fecha']: 'Fecha',
    });

    return data;
  }
}
