import {Component} from '@angular/core';
import {AuthService} from '../../shared/template-services/auth.service';
import {UserService} from '../../shared/services/user.service';
import {Router} from '@angular/router';

import {AlertService} from '../../shared/template-services/alert.service';
import {take} from 'rxjs/operators';
import {User as UserData} from '../../shared/interfaces/user';
import {User} from 'firebase';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  user: User;
  loginForm: FormGroup;
  submitted: boolean = false;

  constructor(private _auth: AuthService,
              private _user: UserService,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.loginForm = formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  async login(): Promise<void> {
    this.submitted = true;
    if (this.loginForm.valid) {

      this._auth.reAuth(this.loginForm.value.email, this.loginForm.value.password)
        .then(async data => {
          delete this.loginForm.value['password'];
          const dataUser = await this._user.getSpecificUser(data.user.uid);
          if (!dataUser || dataUser.firstLogin == true) return this.router.navigateByUrl('first-login');
          this.router.navigateByUrl('admin/home/default');
        })
        .catch(err => {
          AlertService.toastError('Error', AuthService.getMessageError(err.code));
        });
    }
  }

  async onGoogleLogin() {
    this.user = (await this._auth.loginGoogle()).user;

    const whitelist = await this._auth.getWhiteList().pipe(take(1)).toPromise();
    const userFinded: any = whitelist.find((item: any) => item.email == this.user.email);
    if (!userFinded) {
      AlertService.error(`El usuario ${this.user.email} no tiene acceso`);
      return this._auth.signOut();
    }

    const data = await this._user.getSpecificUser(this.user.uid);

    if (!data) {
      await this._user.addUserByKey(this.user.uid, {
        email: this.user.email,
        name: this.user.displayName,
        phone: null,
        imageUrl: this.user.photoURL,
        isDisable: false,
        firstLogin: true,
        permissions: [
          {
            permission: 0,
            section: 'BODEGAS'
          }
        ]
      });
    }

    if (!!data && data.isDisable) {
      AlertService.error('Tu cuenta ha sido deshabilitada', 'Comuníquese con el administrador');
      return this._auth.signOut();
    }

    this._user.update(this.user.uid, {imageUrl: this.user.photoURL} as UserData);
    this._auth.updateWhitelistItem(userFinded.key, {isRegistered: true});
    if (!data || data.firstLogin == true) return this.router.navigateByUrl('first-login');
    this.router.navigateByUrl('admin/home/default');
  }
}
