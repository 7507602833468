export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDMSip7xMDQVBj7EnYgoYkD2lTHi5yZbgY",
    authDomain: "combustibles-507c0.firebaseapp.com",
    databaseURL: "https://combustibles-507c0-default-rtdb.firebaseio.com",
    projectId: "combustibles-507c0",
    storageBucket: "combustibles-507c0.appspot.com",
    messagingSenderId: "913946237722",
    appId: "1:913946237722:web:dd41a60a07667b2fd06aa3",
    measurementId: "G-R2QW5WNFHT"
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  apiBaseURL: 'https://us-central1-colsan-prod.cloudfunctions.net',
  hmr: false,
  useEmulators: true,
  version: 'v 0.0.1'
};
