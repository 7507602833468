import {AfterViewInit, Component, Input} from '@angular/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'GaugeChart',
  template: `
    <div style="width: 100%; height: 300px;" [id]="chartName"></div>
  `,
  styles: []
})
export class GaugeChartComponent implements AfterViewInit {
  @Input() data: any = {};
  @Input() chartName = '';
  @Input() chartMin = 0;
  @Input() chartMax = 50;
  @Input() postFix = 'm3';
  private chart: am4charts.XYChart;
  public hand: any;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart() {
    am4core.useTheme(am4themes_animated);

    function lookUpGrade(lookupScore, grades) {
      for (let i = 0; i < grades.length; i++) {
        if (
          grades[i].lowScore < lookupScore &&
          grades[i].highScore >= lookupScore
        ) {
          return grades[i];
        }
      }
      return null;
    }

    let chart: any = am4core.create(this.chartName, am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(80);
    chart.resizable = true;

    let axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    axis2.min = this.chartMin;
    axis2.max = this.chartMax;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0.5;
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color('#000');
    axis2.renderer.labels.template.fontWeight = 'bold';
    axis2.renderer.labels.template.fillOpacity = 0.3;

    for (let grading of this.data.gradingData) {
      let range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 0.8;
      range.axisFill.zIndex = -1;
      range.value = grading.lowScore > this.chartMin ? grading.lowScore : this.chartMin;
      range.endValue = grading.highScore < this.chartMax ? grading.highScore : this.chartMax;
      range.grid.strokeOpacity = 0;
      range.stroke = am4core.color(grading.color).lighten(-0.1);
      range.label.inside = true;
      range.label.text = grading.title.toUpperCase();
      range.label.inside = true;
      range.label.location = 0.5;
      range.label.inside = true;
      range.label.radius = am4core.percent(10);
      range.label.paddingBottom = -5; // ~half font size
      range.label.fontSize = '0.9em';
    }

    let matchingGrade = lookUpGrade(this.data.score, this.data.gradingData);

    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = '2em';
    label.x = am4core.percent(50);
    label.paddingBottom = 15;
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.text = `${this.data.score.toFixed(1)}m3`;
    label.fill = am4core.color(matchingGrade.color);

    let label2 = chart.radarContainer.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = '1em';
    label2.horizontalCenter = 'middle';
    label2.verticalCenter = 'bottom';
    // label2.text = matchingGrade.title.toUpperCase();
    label2.fill = am4core.color(matchingGrade.color);

    this.hand = chart.hands.push(new am4charts.ClockHand());
    this.hand.axis = axis2;
    this.hand.innerRadius = am4core.percent(55);
    this.hand.startWidth = 8;
    this.hand.pin.disabled = true;
    this.hand.value = this.data.score;
    this.hand.fill = am4core.color('#444');
    this.hand.stroke = am4core.color('#000');

    this.hand.events.on('positionchanged', () => {
      label.text = axis2.positionToValue(this.hand.currentPosition).toFixed(1) + ' ' + this.postFix;
    });

    this.chart = chart;
  }

}
