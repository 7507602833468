// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isHidden?: boolean;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: []
  },
  vertical_menu: {
    items: [
      {
        title: 'Home',
        icon: 'la-home',
        page: '/admin/home/default'
      },
    ]
  }
};




