import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() {
  }

  static printFromHtml(html){
    const WindowPrt = window.open('', '', 'left=0,top=0,width=1200,height=800,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(html)
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(() => {
      WindowPrt.print();
    }, 1000);
  }

  static print(printContent): void {
    const WindowPrt = window.open('', '', 'left=0,top=0,width=1200,height=800,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(`
    <html>
      <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
        <link rel="stylesheet" href="src/assets/sass/main.scss">
        <script src="https://kit.fontawesome.com/cef61353db.js" crossorigin="anonymous"></script>
        <style>
        .imageSize {
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.table-responsive{
overflow-x: hidden !important;
}

.text-small {
    font-size: 12px !important;
}

.add-inventory-btn {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.ticket{
position: absolute;
z-index: 99;
top: 0;
left: 0;
}

.imageSize {
    object-fit: cover;
    height: 50px;
    width: 50px;
}

.floatRight {
            float: right;
        }

        .buttonSize {
            margin-top: 28px;
            height: 40px;
            width: 50px;
        }

        .logoSize {
            height: 75px;
            width: 135px;
            object-fit: contain;
        }

        .footer {
          width: 100%;
          height: 40px;
          position: relative;
          bottom: 0;
          background-color: transparent;
          z-index: 9;
          color: #8b8b8b
        }
        
        .logo {
          height: 100px;
          width: auto !important;
        }
        
        .receipt {
          height: 100vh;
        }
        
        .footer {
          position: absolute;
          bottom: 45px;
        }
        
        .social-border {
          border-top: 1px solid #1c1c1c;
        }
        
        .logo-footer {
          filter: grayscale(100%) opacity(0.8);
          height: 75px;
        }
        </style>
      </head>
      <body style="padding: 35px;">
         <div class="ticket">
            <div class="col-12 d-flex justify-content-start mt-4">
              <img src="/assets/images/logo.png" class="logoSize" alt="company logo"/>
                   <div class="media-body">
                <ul class="mb-0 list-unstyled ml-4">
                  <li>Benito Rebolledo #2470</li>
                  <li>San Joaquin, La Serena.</li>
                  <li>+56 51 2 494893</li>
                </ul>
              </div>
            </div>
         </div>
          
          <div style="padding-top: 105px">
            ${printContent.innerHTML}
          </div>
        
           <div class="social-border footer">
             <div class="d-flex flex-row justify-content-center align-items-center pt-2">
               <img src="/assets/images/logo.png" class="logoSize mr-4" alt="">
             </div>
           </div>
      </body>
    </html>`);
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(() => {
      WindowPrt.print();
    }, 1000);
  }
}
