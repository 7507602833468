<block-ui>
  <div>
    <h3>Último dato obtenido</h3>
    <LastDataComponent [data]="lastData"></LastDataComponent>
  </div>

  <div class="d-flex justify-content-between">
    <div>
      <h3 class="mb-0 text-center">Volumen Estanque RG</h3>
      <GaugeChart
        *ngIf="!!data"
        [data]="data"
        #estanque
        chartName="estanque"></GaugeChart>
    </div>

    <div>
      <h3 class="mb-0 text-center">Flujómetro Generador 1</h3>
      <GaugeChart
        *ngIf="!!flujometrosData"
        [data]="flujometrosData"
        [chartMax]="200"
        postFix="L/h"
        #flujimetro
        chartName="flujimetro"></GaugeChart>
    </div>

    <div>
      <h3 class="mb-0 text-center">Flujómetro Generador 2</h3>
      <GaugeChart
        *ngIf="!!flujometrosData"
        [data]="flujometrosData"
        [chartMax]="200"
        postFix="L/h"
        #flujimetro2
        chartName="flujimetro2"></GaugeChart>
    </div>
  </div>

  <div class="d-flex flex-row justify-content-around">
    <div class="d-flex flex-column m-2 w-100">
      <h6 class="m-0">Desde:</h6>
      <input type="date" class="form-control" placeholder="Desde" [(ngModel)]="startDate">
    </div>

    <div class="d-flex flex-column m-2 w-100">
      <h6 class="m-0">Hasta:</h6>
      <input type="date" class="form-control" placeholder="Hasta" [(ngModel)]="endDate">
    </div>
  </div>

  <button class="btn btn-primary float-right ml-2" (click)="filterData()" *ngIf="isFilter">Filtrar</button>
  <button class="btn btn-warning float-right"
          (click)="toggleFilter()">{{isFilter ? 'Ver datos en tiempo real' : 'Ver datos por filtro'}}</button>

  <div class="mt-4" style="min-height: 450px" *ngIf="isFilter">
    <h3 class="text-center">Combustible RG</h3>
    <CombustibleRgChartComponent *ngIf="!!combustibleRgDataByRangeFiler"
                                 [data]="combustibleRgDataByRangeFiler"></CombustibleRgChartComponent>
  </div>

  <div class="mt-4" style="min-height: 450px" *ngIf="!isFilter">
    <h3 class="text-center">Combustible RG</h3>
    <CombustibleRgChartComponent *ngIf="!!combustibleRgData"
                                 [data]="combustibleRgData"></CombustibleRgChartComponent>
  </div>

  <div class="mt-4">
    <h3 class="text-center">Valores de Sensores</h3>
    <LastHourChartComponent *ngIf="!!lastHourFlujometro2Data"
                            [estanqueData]="lastHourEstanqueData"
                            [flujometro1Data]="lastHourFlujometro1Data"
                            [flujometro2Data]="lastHourFlujometro2Data"></LastHourChartComponent>
  </div>

  <div class="mt-4 d-flex justify-content-center ">
    <angular2csv id="downloadLastHour" [data]="lastHour" filename="Última hora.csv" [options]="options"></angular2csv>

    <angular2csv id="downloadLastWeek" [data]="lastWeek" filename="Última semana.csv"
                 [options]="options"></angular2csv>
  </div>

  <div class="mt-4">
    <h3>Tabla Resumen</h3>
    <SummaryComponent *ngIf="!!summaryDate" [data]="summaryDate"></SummaryComponent>
  </div>

</block-ui>
